<template>
    <v-container fluid>
        <v-alert :value="alert" dense type="info">{{msg}}</v-alert>
        <v-text-field label="部門コード" value="" v-model="MTpBsTnpCd" :counter="5"  clearable dense/>
        <v-text-field label="部門名称" value="" v-model="MTpBsTnpNm"  clearable dense/>
        <v-btn color="warning" @click="submit">部門登録(post)</v-btn>
        <v-btn color="error" class="mr-4" @click="reset">リセット</v-btn>
        <v-text-field v-model="search" label="部門マスタ内文字検索" append-icon="mdi-magnify"></v-text-field>
        <v-data-table :headers="headers" :items="items" :items-per-page="15"  :search="search" :custom-filter="filterOnlyCapsText"  loading-text="読込中" @click:row="clickRow"/>
   </v-container>
 
</template>
<script>
    import axios from 'axios';
    export default {
    data: () => ({
        MTpBsTnpCd: '', //部署コード
        MTpBsTnpNm: '', //部署名称
        msg: 'error ',
        alert:false,
        search: '',
        items: [],
        headers: [
            {text:'部門コード',value: 'MTpBsTnpCd'},
            {text:'部門名称', value: 'MTpBsTnpNm' },
        ],
     }),

   //初期取得
   mounted: function() {
     this.MTpBsselect();
    },
    methods: {
    //--------------------------------------------------------------------
    //部門マスタ追加 post
    //--------------------------------------------------------------------
    submit() {
        const fomego = { MTpBsTnpCd: this.MTpBsTnpCd, MTpBsTnpNm: this.MTpBsTnpNm};
        axios.post('/api/TpBsMstAPS',fomego)
           .then(response => {
                this.desserts = response
                this.MTpBsTnpCd = '';
                this.MTpBsTnpNm = '';
                this.items = [];
                this.MTpBsselect();
            })
                .catch(error => {
                console.log(error)
            })
    },
     //全体表示
        MTpBsselect() {
        axios.get('/api/TpBsMstALL')
            .then(response => {
                this.items = response.data;
            })
            .catch(error => {
                console.log(error)
            })
        },
        //画面のリセット
        reset () {
            this.MTpBsTnpCd = '';
            this.MTpBsTnpNm = '';
        },
        //行が押された時の処理
        clickRow(row) {
           //console.log(row) // 
           this.MTpBsTnpCd = row.MTpBsTnpCd;
           this.MTpBsTnpNm = row.MTpBsTnpNm;
        },
        //検索
        filterOnlyCapsText (value, search) {
          return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
      },
   }
</script>